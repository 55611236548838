import {
  PaymentMethod,
  PaymentProvider,
} from '@app/akita/api/checkout/models/payment.provider.model';
import { Insurance } from '@app/akita/api/products/models/insurance.model';
import { Product } from '@app/akita/api/products/models/product.model';
import { CartItem } from '@app/akita/cart/models/cart.model';
import { ReportOverlayMode } from '@app/overlays/report-overlay/models/report-overlay-config.interface';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { PriceModel } from '@app/shared/models/api/price.model';
import { UserModel } from '@app/shared/models/api/user.model';

export type LogFn = (
  eventName: string,
  eventParams: { [id: string]: any },
  category: string,
  label: string,
  options?: any
) => void;

export const logOpenPage = (
  recordPageView: (name: string, view: string, path?: string | null) => void,
  firebaseSetCurrentScreenWrapper: (screenName: string, options?: any | null) => void,
  name: string,
  view: string,
  path?: string | null
): void => {
  firebaseSetCurrentScreenWrapper(view);
  recordPageView(name, view, path);
};

export const logLogin = (
  authFn: (eventName: string, user: UserModel | null) => void,
  user: UserModel | null
): void => {
  authFn('login', user);
};

export const logLogout = (
  authFn: (eventName: string, user: UserModel | null) => void,
  user: UserModel | null
): void => {
  authFn('logout', user);
};

export const logAuthDialogShown = (logFn: LogFn, name?: string | null): void => {
  logFn(
    'auth_dialog_shown',
    {
      trigger: name || '??',
    },
    'application',
    'auth'
  );
};

export const logErrorDialogShown = (
  logFn: LogFn,
  title: string,
  description: string,
  userID?: string | null,
  sessionID?: string | null
): void => {
  logFn(
    'error_dialog_shown',
    {
      title: title || '??',
      description: description || '??',
      id: userID || 'anonymous',
      sessionID: sessionID || '??',
    },
    'application',
    'error'
  );
};

export const logSelectedFeedbackRating = (
  logFn: LogFn,
  viewName: string,
  viewStep: string,
  analyticsName: string,
  rating: number,
  email?: string | null
): void => {
  logFn(
    'rate_app_feedback',
    {
      value: rating,
      email: email || '',

      // Context
      view: viewName,
      step: viewStep,
      feature: analyticsName,
    },
    'application',
    ''
  );
};

export const logInAppReviewSubmitted = (
  logFn: LogFn,
  provider: string,
  numberOfStars: number,
  trigger: string
): void => {
  logFn(
    'in_app_review_submitted',
    {
      provider: provider,
      number_of_stars: numberOfStars,
      trigger: trigger,
    },
    'application',
    ''
  );
};

export const logSentFeedbackRating = (
  logFn: LogFn,
  viewName: string,
  viewStep: string,
  analyticsName: string,
  rating: number,
  comments: string,
  email?: string | null
): void => {
  logFn(
    'rate_app_feedback_sent',
    {
      value: rating,
      comments: comments,
      email: email || '',

      // Context
      view: viewName,
      step: viewStep,
      feature: analyticsName,
    },
    'application',
    ''
  );
};

export const logConfirmDialogShown = (
  logFn: LogFn,
  title: string,
  description: string,
  userID: string
): void => {
  logFn(
    'confirm_dialog_shown',
    {
      title: title || '??',
      description: description || '??',
      id: userID || 'anonymous',
    },
    'application',
    'prompt'
  );
};

export const logLeaveOrderSurvey = (
  logFn: LogFn,
  question: string,
  userID: string,
  psid: string
): void => {
  logFn(
    'leave_survey_shown',
    {
      question: question || '??',
      id: userID || 'anonymous',
      psid: psid || '??',
    },
    'application',
    'survey'
  );
};

export const logSignupEmail = (
  authFn: (eventName: string, user: UserModel | null) => void,
  user: UserModel | null
): void => {
  authFn('signup_email', user);
};

export const logSignupFacebook = (
  authFn: (eventName: string, user: UserModel | null) => void,
  user: UserModel | null
): void => {
  authFn('signup_facebook', user);
};

export const logSignupGoogle = (
  authFn: (eventName: string, user: UserModel | null) => void,
  user: UserModel | null
): void => {
  authFn('signup_google', user);
};

export const logSignupGoogleRequest = (logFn: LogFn): void => {
  logFn('signup_google_requested', {}, 'engagement', 'method');
};

export const logSignupApple = (
  authFn: (eventName: string, user: UserModel | null) => void,
  user: UserModel | null
): void => {
  authFn('signup_apple', user);
};

export const logMessageReceived = (logFn: LogFn): void => {
  logFn('message_receive', {}, 'inbox', 'message');
};

export const logAccountDeleted = (logFn: LogFn): void => {
  logFn('account_deleted', {}, 'engagement', 'method');
};

export const logVerifySMSOpen = (logFn: LogFn): void => {
  logFn('sms_verify_open', {}, 'verification', 'sms');
};

export const logVerifySMSConfirmed = (logFn: LogFn): void => {
  logFn('sms_verify_confirmed', {}, 'verification', 'sms');
};

export const logVerifySMSFailed = (
  logFn: LogFn,
  reason?: string | null,
  errorCode?: string | null,
  errorMessage?: string | null
): void => {
  logFn(
    'sms_verify_failed',
    {
      reason: reason,
      code: errorCode,
      message: errorMessage,
    },
    'verification',
    'sms'
  );
};

export const logSocialShare = (
  logFn: LogFn,
  socialNetwork: string,
  linkType: 'user' | 'listing' | 'search' | '',
  id: string
): void => {
  logFn(
    'share',
    {
      method: socialNetwork,
      content_type: linkType,
      content_id: id,
    },
    'engagement',
    'method'
  );
};

export const logReport = (
  logFn: LogFn,
  reportChannel: 'email' | 'zendesk',
  linkType: ReportOverlayMode,
  hasComments: boolean,
  isAnonymous: boolean,
  locale: string
): void => {
  logFn(
    'report',
    {
      report_channel: reportChannel,
      link_type: linkType,
      has_comments: hasComments === true,
      anonymous: isAnonymous === true,
      locale: locale,
    },
    'engagement',
    'method'
  );
};

export const logReportWebsiteVersion = (
  logFn: LogFn,
  version: string,
  commit: string
): void => {
  logFn(
    'website_version',
    {
      version: version,
      commit: commit,
    },
    'application',
    ''
  );
};

/**
 * DELIVERIES
 */
export const logShowAddToCart = (
  logFn: LogFn,
  trigger: 'search' | 'detail' | 'chat'
): void => {
  logFn(
    'add_to_cart_shown',
    {
      trigger: trigger,
    },
    'ecommerce',
    ''
  );
};

export const logContinueToDeliveryOverview = (
  firebaseRecordAddressAndPhoneADConversion: () => void
): void => {
  firebaseRecordAddressAndPhoneADConversion();
};

export const logViewItem = (
  logFn: LogFn,
  firebaseRecordProductPageViewADConversion: () => void,
  facebookTrackViewContent: (product: Product) => void,
  product: Product
): void => {
  firebaseRecordProductPageViewADConversion();
  logFn(
    'view_item',
    {
      currency: product.price.currency || 'USD',
      value: product.price.amount || 0,
      items: [
        {
          item_id: product.id,
          item_name: product.title,
          item_category: product.category,
          quantity: 1,
          price: product.price.amount || 0,
        },
      ],
    },
    'engagement',
    ''
  );
  facebookTrackViewContent(product);
};

export const logSelectPromotion = (
  logFn: LogFn,
  product?: Product | null,
  promotionId?: string | null,
  promotionName?: string | null,
  creativeName?: string | null,
  creativeSlot?: string | null,
  locationId?: string | null
): void => {
  logFn(
    'select_promotion',
    {
      promotion_id: promotionId,
      promotion_name: promotionName,
      creative_name: creativeName,
      creative_slot: creativeSlot,
      location_id: locationId,
      items: product
        ? [
            {
              item_id: product.id,
              item_name: product.title,
              item_category: product.category,
              quantity: 1,
              price: product.price.amount || 0,
            },
          ]
        : undefined,
    },
    'engagement',
    ''
  );
};

export const logAddToCart = (
  logFn: LogFn,
  firebaseRecordAddToCartADConversion: () => void,
  facebookTrackAddToCart: (product: Product) => void,
  product: Product | null,
  listName: string
): void => {
  firebaseRecordAddToCartADConversion();
  if (product) {
    logFn(
      'add_to_cart',
      {
        currency: product.price.currency,
        items: [
          {
            id: product.id,
            name: product.title || 'empty-field',
            list_name: listName,
            category: (product as any)?.category || undefined,
            brand:
              (product as any)?.brand ||
              (product as any)?.phoneMetadata?.brand ||
              undefined,
            color:
              (product as any)?.color ||
              (product as any)?.phoneMetadata?.color ||
              undefined,
            model:
              (product as any)?.model ||
              (product as any)?.phoneMetadata?.model ||
              undefined,
            storage:
              (product as any)?.storage ||
              (product as any)?.phoneMetadata?.storageInGB ||
              undefined,
            quantity: 1,
            price: product.price.amount,
            location_id: product?.country || undefined,
          },
        ],
      },
      'ecommerce',
      ''
    );
    facebookTrackAddToCart(product);
  }
};

export const logAddInsuranceToCart = (
  logFn: LogFn,
  insurance: Insurance | null,
  trigger: 'product_page' | 'cart_popup' | 'cart' | string | null
): void => {
  if (insurance) {
    logFn(
      'add_insurance_to_cart',
      {
        id: insurance.id,
        description: insurance.description,
        quantity: insurance.quantity || 1,
        price: insurance.price.amount,
        currency: insurance.price.currency,
        trigger: trigger,
      },
      'ecommerce',
      ''
    );
  }
};

export const logAddAccessoryToCart = (
  logFn: LogFn,
  product: Product | null,
  trigger: 'product_page' | 'cart_popup' | 'cart' | string | null
): void => {
  if (product) {
    logFn(
      'add_accessory_to_cart',
      {
        id: product.id,
        description: product.description,
        quantity: product.quantity || 1,
        price: product.price.amount,
        currency: product.price.currency,
        trigger: trigger,
      },
      'ecommerce',
      ''
    );
  }
};

export const logViewCart = (logFn: LogFn, orders: Array<CartItem>): void => {
  if (orders && orders.length > 0) {
    const items = new Array(0);
    let currency = 'USD';
    for (const order of orders) {
      if (!currency && order.product.price?.currency) {
        currency = order.product.price?.currency;
      }

      items.push({
        id: order.product.id,
        name: order.product.title,
        category: order.product.category,
        quantity: order.quantity,
        price: order.product.price?.amount,
        location_id: order.product.country || undefined,
      });
    }

    logFn(
      'view_cart',
      {
        currency: currency,
        items: items,
      },
      'ecommerce',
      ''
    );
  }
};

export const logBeginCheckout = (
  logFn: LogFn,
  firebaseRecordAddToCartADConversion: () => void,
  facebookTrackInitiateCheckout: (products: Array<Product>) => void,
  products: Array<Product> | null,
  totalPrice: PriceModel | null,
  listName: string,
  guestCheckout?: boolean | null
): void => {
  firebaseRecordAddToCartADConversion();
  if (products?.length) {
    logFn(
      'begin_checkout',
      {
        currency: totalPrice?.currency || 'SAR',
        items: products?.map(
          (product) =>
            <any>{
              id: product?.id || undefined,
              name: product?.title || undefined,
              listName: listName,
              category: product?.category || undefined,
              quantity: product?.quantity || 1,
              price: product?.price?.amount || undefined,
              location_id: product?.country || undefined,
            }
        ),
        is_guest: guestCheckout,
        value: totalPrice?.amount || 0,
      },
      'ecommerce',
      ''
    );
    facebookTrackInitiateCheckout(products);
  }
};

export const logBeginPaymentOverview = (
  logFn: LogFn,
  products: Array<Product> | null,
  listName: string,
  guestCheckout?: boolean | null,
  newCheckout?: boolean | null
): void => {
  if (products?.length) {
    logFn(
      'begin_payment_overview',
      {
        currency: products[0].price.currency || 'SAR',
        items: products?.map(
          (product) =>
            <any>{
              id: product?.id || undefined,
              name: product?.title || undefined,
              listName: listName,
              category: product?.category || undefined,
              quantity: product?.quantity || 1,
              price: product?.price?.amount || undefined,
              location_id: product?.country || undefined,
            }
        ),
        is_guest: guestCheckout,
        new_checkout: newCheckout || false,
      },
      'ecommerce',
      ''
    );
  }
};

export const logExpressCheckout = (
  logFn: LogFn,
  provider: string | null,
  guestCheckout?: boolean | null,
  newCheckout?: boolean | null
): void => {
  logFn(
    'express_checkout',
    {
      provider: provider || '??',
      is_guest: guestCheckout,
      new_checkout: newCheckout || false,
    },
    'ecommerce',
    ''
  );
};

export const logRemoveFromCart = (
  logFn: LogFn,
  product: Product | null,
  listName: string
): void => {
  if (product) {
    logFn(
      'remove_from_cart',
      {
        currency: product.price.currency,
        items: [
          {
            id: product.id,
            name: product.title,
            list_name: listName,
            category: product.category,
            quantity: 1,
            price: product.price.amount,
          },
        ],
      },
      'ecommerce',
      ''
    );
  }
};

export const logRemoveInsuranceFromCart = (
  logFn: LogFn,
  insurance: Insurance | null,
  trigger: string | null
): void => {
  if (insurance) {
    logFn(
      'remove_insurance_from_cart',
      {
        id: insurance.id,
        description: insurance.description,
        quantity: insurance.quantity || 1,
        price: insurance.price.amount,
        currency: insurance.price.currency,
        trigger: trigger,
      },
      'ecommerce',
      ''
    );
  }
};

export const logAddShippingInfo = (
  logFn: LogFn,
  products: Array<Product> | null,
  currency: string,
  deliveryProvider: string,
  popsyFee: number
): void => {
  let value = popsyFee;
  if (products) {
    if (popsyFee <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      value = (products[0]?.price?.amount || 0) * 0.1; // 10% of the price if no fee
    }

    logFn(
      'add_shipping_info',
      {
        currency: currency,
        shipping_tier: deliveryProvider,
        value: value,
        items: products.forEach(
          (product) =>
            <any>{
              id: product?.id || undefined,
              name: product?.title || undefined,
              category: product?.category || undefined,
              quantity: 1,
              price: product?.price?.amount || undefined,
              location_id: product?.country || undefined,
            }
        ),
      },
      'ecommerce',
      ''
    );
  }
};

export const logAddPaymentInfo = (
  logFn: LogFn,
  facebookTrackAddPaymentInfo: (
    id: string | Array<string>,
    amount: number,
    currency: string
  ) => void,
  id: string | Array<string>,
  amount: number,
  currency: string,
  provider: string | null,
  guestCheckout?: boolean | null
): void => {
  logFn(
    'add_payment_info',
    {
      is_guest: guestCheckout,
      provider: provider || '??',
    },
    'ecommerce',
    ''
  );
  facebookTrackAddPaymentInfo(id, amount, currency);
};

export const logCreatePayment = (
  logFn: LogFn,
  facebookTrackAddPaymentInfo: (
    id: string | Array<string>,
    amount: number,
    currency: string
  ) => void,
  id: string | Array<string>,
  amount: number,
  currency: string,
  provider: string | null,
  method: string | null,
  guestCheckout?: boolean | null,
  firstIntent?: boolean | null
): void => {
  logFn(
    'create_payment',
    {
      amount: amount,
      currency: currency,
      is_guest: guestCheckout,
      provider: provider || '??',
      method: method || '??',
      first_intent: firstIntent,
    },
    'ecommerce',
    ''
  );
  facebookTrackAddPaymentInfo(id, amount, currency);
};

export const logRequestOTP = (
  logFn: LogFn,
  id: string | Array<string>,
  provider: string | null,
  method: string | null,
  guestCheckout?: boolean | null,
  firstIntent?: boolean | null
): void => {
  logFn(
    'request_otp',
    {
      id: id,
      is_guest: guestCheckout,
      provider: provider || '??',
      method: method || '??',
      first_intent: firstIntent,
    },
    'ecommerce',
    ''
  );
};

export const logSentOTP = (
  logFn: LogFn,
  id: string | Array<string>,
  provider: string | null,
  method: string | null,
  guestCheckout?: boolean | null,
  firstIntent?: boolean | null
): void => {
  logFn(
    'sent_otp',
    {
      id: id,
      is_guest: guestCheckout,
      provider: provider || '??',
      method: method || '??',
      first_intent: firstIntent,
    },
    'ecommerce',
    ''
  );
};

export const logChangedStcPhoneNumber = (
  logFn: LogFn,
  guestCheckout?: boolean | null
): void => {
  logFn(
    'changed_stc_phone_number',
    {
      is_guest: guestCheckout,
    },
    'ecommerce',
    ''
  );
};

export const logStcErrorPayment = (
  logFn: LogFn,
  errorCode: string,
  errorMessage: string
): void => {
  logFn(
    'stc_payment_error',
    {
      code: errorCode,
      message: errorMessage,
    },
    'ecommerce',
    ''
  );
};

export const logPrePurchase = (
  logFn: LogFn,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _firebaseRecordPurchaseADConversion: (deliveryId: string) => void,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _facebookTrackPurchase: (
    products: Array<Product>,
    popsyFee: number,
    currency: string
  ) => void,
  deliveryId: string,
  products: Array<Product>,
  popsyFee: number,
  deliveryPrice: number,
  currency: string,
  listName: string,
  provider: PaymentProvider | null,
  guestCheckout?: boolean | null
): void => {
  // Orders are now created when user enters the delivery area, but they are not performed until later on.
  // firebaseRecordPurchaseADConversion(deliveryId);

  let value = popsyFee;
  if (popsyFee <= 0) {
    value =
      (products?.reduce(
        (total, current) => total + current?.price.amount * (current?.quantity || 1),
        0
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      ) || 0) * 0.1;
  }

  logFn(
    'place_order',
    {
      transaction_id: deliveryId,
      value: value,
      currency: currency,
      shipping: deliveryPrice,
      list_name: listName,
      provider: provider || '??',
      items: products?.map(
        (product) =>
          <any>{
            id: product?.id || undefined,
            name: product?.title || undefined,
            category: product?.category || undefined,
            quantity: product?.quantity || 1,
            price: product?.price?.amount || undefined,
            location_id: product?.country || undefined,
          }
      ),
      is_guest: guestCheckout,
    },
    'ecommerce',
    ''
  );

  // Orders are now created when user enters the delivery area, but they are not performed until later on.
  // if (listing) {
  //   facebookTrackPurchase(listing, value, currency);
  // }
};

export const logPurchase = (
  logFn: LogFn,
  firebaseRecordPurchaseADConversion: (
    deliveryId: string,
    value?: number | null,
    currency?: string | null
  ) => void,
  facebookTrackPurchase: (
    product: Array<Product>,
    popsyFee: number,
    currency: string
  ) => void,
  deliveryId: string,
  products: Array<Product> | null,
  popsyFee: number,
  deliveryPrice: number,
  currency: string,
  listName: string,
  provider: PaymentProvider | null,
  method?: PaymentMethod | null,
  guestCheckout?: boolean | null,
  isProtected?: boolean | null
): void => {
  let value =
    (products?.reduce(
      (total, current) => total + current?.price.amount * (current?.quantity || 1),
      0
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    ) || 0) * 0.1;

  if (value === 0) {
    // fallback if value is null
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    value = 100;
    currency = 'SAR';
  }
  firebaseRecordPurchaseADConversion(deliveryId, value, currency);

  logFn(
    'purchase',
    {
      transaction_id: deliveryId,
      value: value,
      currency: currency,
      shipping: deliveryPrice,
      list_name: listName,
      provider: provider || '??',
      method: method || '??',
      items: products?.map(
        (product) =>
          <any>{
            id: product?.id || undefined,
            name: product?.title || undefined,
            category: product?.category || undefined,
            quantity: product?.quantity || 1,
            price: product?.price?.amount || undefined,
            location_id: product?.country || undefined,
          }
      ),
      is_guest: guestCheckout,
      popsy_fee: popsyFee || undefined,
      is_protected: isProtected || false,
    },
    'ecommerce',
    ''
  );

  if (products) {
    facebookTrackPurchase(products, value, currency);
  }
};

export const logPurchaseInsurance = (
  logFn: LogFn,
  sessionId: string,
  insurances: Array<Insurance> | null,
  provider: PaymentProvider | null,
  method?: PaymentMethod | null
): void => {
  if (insurances?.length) {
    logFn(
      'purchase_insurance',
      {
        transaction_id: sessionId,
        provider: provider || '??',
        method: method || '??',
        items: insurances?.map(
          (insurance) =>
            <any>{
              id: insurance?.id || undefined,
              description: insurance?.description || undefined,
              quantity: insurance?.quantity || 1,
              price: insurance?.price?.amount || undefined,
              currency: insurance?.price?.currency || undefined,
            }
        ),
      },
      'ecommerce',
      ''
    );
  }
};

export const logSessionCreated = (
  logFn: LogFn,
  triggeredBy: string,
  guestCheckout?: boolean | null
): void => {
  logFn(
    'session_created',
    {
      triggered_by: triggeredBy,
      trigger: triggeredBy,
      mode: 'cep',
      is_guest: guestCheckout,
    },
    'ecommerce',
    ''
  );
};

export const logOnCreditCardPayment = (
  logFn: LogFn,
  recordPaymentConversion: () => void
): void => {
  recordPaymentConversion();
  logFn('on_credit_card_payment', {}, 'ecommerce', 'step');
};

export const logOnApplePay = (
  logFn: LogFn,
  recordPaymentConversion: () => void
): void => {
  recordPaymentConversion();
  logFn('on_apple_pay_payment', {}, 'ecommerce', 'step');
};

export const logOnGooglePay = (
  logFn: LogFn,
  recordPaymentConversion: () => void
): void => {
  recordPaymentConversion();
  logFn('on_google_pay_payment', {}, 'ecommerce', 'step');
};

export const logOnApplePayCancelled = (logFn: LogFn): void => {
  logFn('on_apple_pay_cancelled', {}, 'ecommerce', 'step');
};

export const logOnGooglePayCancelled = (logFn: LogFn): void => {
  logFn('on_google_pay_cancelled', {}, 'ecommerce', 'step');
};

export const logOnApplePayError = (logFn: LogFn, err: ApiError): void => {
  logFn(
    'on_apple_pay_error',
    {
      code: err.code,
      message: err.message,
    },
    'ecommerce',
    'step'
  );
};

export const logOnGooglePayError = (logFn: LogFn, err: ApiError): void => {
  logFn(
    'on_google_pay_error',
    {
      code: err.code,
      message: err.message,
    },
    'ecommerce',
    'step'
  );
};

export const logOneTapDisplayed = (
  logFn: LogFn,
  hasBeenDisplayed: boolean,
  reason: string
): void => {
  logFn(
    'login_one_tap_displayed',
    {
      displayed: hasBeenDisplayed,
      reason: reason,
    },
    'application',
    ''
  );
};

export const logOneTapDismissed = (
  logFn: LogFn,
  hasBeenDisplayed: boolean,
  reason: string
): void => {
  logFn(
    'login_one_tap_error',
    {
      displayed: hasBeenDisplayed,
      reason: reason,
    },
    'application',
    'error'
  );
};

export const logOneTapSuccess = (logFn: LogFn, source: string): void => {
  logFn(
    'login_one_tap',
    {
      source: source,
    },
    'application',
    ''
  );
};

export const logRateDeliveryFlowBuyer = (logFn: LogFn, value: number): void => {
  logFn(
    'rate_delivery_flow_buyer',
    {
      value: value,
    },
    'application',
    ''
  );
};
export const logWindowUnloaded = (logFn: LogFn): void => {
  logFn('window_unloaded', {}, 'application', '');
};

export const logNewsletterOpen = (logFn: LogFn, picture: string): void => {
  logFn(
    'newsletter_open',
    {
      variant: picture,
    },
    'marketing',
    'email'
  );
};

export const logNewsletterSubscribed = (logFn: LogFn, picture: string): void => {
  logFn(
    'newsletter_email',
    {
      variant: picture,
    },
    'marketing',
    'email'
  );
};

export const logNewsletterSubscribeFailed = (logFn: LogFn, email: string): void => {
  logFn(
    'newsletter_email_failed',
    {
      email: email,
    },
    'marketing',
    'email'
  );
};

export const logNewsletterPhone = (
  logFn: LogFn,
  picture: string,
  phone: string
): void => {
  logFn(
    'newsletter_phone',
    {
      phone: phone,
      variant: picture,
    },
    'marketing',
    'phone'
  );
};

export const logPaymentMethodSelected = (
  logFn: LogFn,
  provider: string,
  method: string,
  country: string
): void => {
  logFn(
    'payment_method_selected',
    {
      provider: provider,
      method: method,
      country: country,
    },
    'web',
    'phone'
  );
};

export const logPaymentInfoCardClicked = (logFn: LogFn, trigger: string): void => {
  logFn(
    'payment_info_card_clicked',
    {
      trigger: trigger,
    },
    'web',
    ''
  );
};

export const logDeclinedPaymentPopupShown = (logFn: LogFn, country: string): void => {
  logFn(
    'declined_payment_popup_shown',
    {
      country: country,
    },
    'web',
    ''
  );
};

export const logDeclinedPaymentPopupIgnored = (logFn: LogFn, country: string): void => {
  logFn(
    'declined_payment_popup_ignored',
    {
      country: country,
    },
    'web',
    ''
  );
};

export const logRetryDeclinedPayment = (
  logFn: LogFn,
  method: string,
  country: string
): void => {
  logFn(
    'retry_declined_payment',
    {
      method: method,
      country: country,
    },
    'web',
    ''
  );
};

export const logSearch = (logFn: LogFn, searchTerm: string, country: string): void => {
  logFn(
    'search',
    {
      search_term: searchTerm,
      country: country,
      platform: 'web',
    },
    'web',
    'phone'
  );
};

export const logSortBy = (logFn: LogFn, searchTerm: string, country: string): void => {
  logFn(
    'sort_by',
    {
      search_term: searchTerm,
      country: country,
    },
    'web',
    'phone'
  );
};

export const logSearchForAddress = (logFn: LogFn, country: string): void => {
  logFn(
    'search_for_address',
    {
      country: country,
    },
    'web',
    'phone'
  );
};

export const logAutocompleteAddress = (logFn: LogFn, country: string): void => {
  logFn(
    'autocomplete_address',
    {
      country: country,
    },
    'web',
    'phone'
  );
};

export const logStripeLoadingStart = (logFn: LogFn, country: string): void => {
  logFn(
    'stripe_loading',
    {
      country: country,
      message: 'Stripe started loading',
    },
    'stripe_debug',
    'stripe'
  );
};

export const logStripeLoadingDelay = (logFn: LogFn, country: string): void => {
  logFn(
    'stripe_delay',
    {
      country: country,
      message: 'Stripe could not load after 4s',
    },
    'stripe_debug',
    'stripe'
  );
};

export const logStripeLoadingError = (
  logFn: LogFn,
  country: string,
  error: string | null,
  message: string | null
): void => {
  logFn(
    'stripe_error',
    {
      country: country,
      error_name: error,
      message: message,
    },
    'stripe_debug',
    'stripe'
  );
};

export const logStripeLoadingDone = (logFn: LogFn, country: string): void => {
  logFn(
    'stripe_success',
    {
      country: country,
      message: 'Stripe loaded correctly',
    },
    'stripe_debug',
    'stripe'
  );
};

export const logStripeLoadingDoneAfterError = (logFn: LogFn, country: string): void => {
  logFn(
    'stripe_success_after_error',
    {
      country: country,
      message: 'Stripe loaded correctly after errror',
    },
    'stripe_debug',
    'stripe'
  );
};

export const logStripeLoadingDoneAfterForced = (logFn: LogFn, country: string): void => {
  logFn(
    'stripe_success_after_forced',
    {
      country: country,
      message: 'Stripe loaded correctly after forced reload',
    },
    'stripe_debug',
    'stripe'
  );
};

export const logRedirectToShopify = (logFn: LogFn, country: string): void => {
  logFn(
    'sent_to_shopify_checkout',
    {
      country: country,
    },
    'debug',
    'shopify'
  );
};

export const logTamaraOptionSelected = (
  logFn: LogFn,
  variant: string,
  country: string,
  phoneNumber?: string
): void => {
  logFn(
    'tamara_option_selected',
    {
      variant: variant,
      country: country,
      phone: phoneNumber,
    },
    'debug',
    'tamara-test'
  );
};

export const logTamaraGotRedirected = (
  logFn: LogFn,
  variant: string,
  country: string,
  phoneNumber?: string
): void => {
  logFn(
    'tamara_got_redirected',
    {
      variant: variant,
      country: country,
      phone: phoneNumber,
    },
    'debug',
    'tamara-test'
  );
};

export const logTamaraCallbackFail = (
  logFn: LogFn,
  variant: string,
  country: string,
  phoneNumber?: string
): void => {
  logFn(
    'tamara_callback_fail',
    {
      variant: variant,
      country: country,
      phone: phoneNumber,
    },
    'debug',
    'tamara-test'
  );
};

export const logTamaraCallbackSuccess = (
  logFn: LogFn,
  variant: string,
  country: string,
  phoneNumber?: string
): void => {
  logFn(
    'tamara_callback_success',
    {
      variant: variant,
      country: country,
      phone: phoneNumber,
    },
    'debug',
    'tamara-test'
  );
};

export const logTamaraPreCheckApproved = (
  logFn: LogFn,
  value: boolean,
  country: string
): void => {
  logFn(
    'tamara_pre_check_approved',
    {
      value: value,
      is_approved: value,
      country: country,
    },
    'debug',
    'tamara-test'
  );
};

export const logTabbyPreCheckApproved = (
  logFn: LogFn,
  value: boolean,
  country: string,
  reason?: string | null
): void => {
  logFn(
    'tabby_pre_check_approved',
    {
      value: value,
      is_approved: value,
      country: country,
      description: reason,
    },
    'debug',
    'tabby-test'
  );
};

export const logOnCreditCardSuccess = (
  logFn: LogFn,
  variant: string,
  country: string
): void => {
  logFn(
    'credit_card_success',
    {
      variant: variant,
      country: country,
    },
    'debug',
    'checkout-test'
  );
};

export const logOnCreditCardFormLoaded = (
  logFn: LogFn,
  variant: string,
  country: string
): void => {
  logFn(
    'credit_card_form_loaded',
    {
      variant: variant,
      country: country,
    },
    'debug',
    'checkout-test'
  );
};

export const logOnCreditCardSubmitted = (
  logFn: LogFn,
  variant: string,
  country: string
): void => {
  logFn(
    'credit_card_submitted',
    {
      variant: variant,
      country: country,
    },
    'debug',
    'checkout-test'
  );
};

export const logQuaraRedirectTimeout = (
  logFn: LogFn,
  sessionId: string,
  paymentId: string,
  browserCountry: string,
  linkUrl: string
): void => {
  logFn(
    'quara_redirect_timeout',
    {
      session_id: sessionId,
      payment_id: paymentId,
      country: browserCountry,
      link_url: linkUrl,
    },
    'debug',
    'checkout-test'
  );
};

export const logBaseetaOnboardingScriptOpen = (
  logFn: LogFn,
  page: string | 'info-page'
): void => {
  logFn(
    'baseeta_onboarding_script_open',
    {
      page_name: page,
    },
    'debug',
    'checkout-test'
  );
};

export const logBaseetaOnboardingRedirected = (
  logFn: LogFn,
  page: string | 'info-page',
  phoneNumber?: string | null,
  email?: string | null
): void => {
  logFn(
    'baseeta_onboarding_redirected',
    {
      page_name: page,
      phoneNumber: phoneNumber,
      email: email,
    },
    'debug',
    'checkout-test'
  );
};

export const logBaseetaOnboardingRedirectedTimeout = (
  logFn: LogFn,
  page: string | 'info-page',
  phoneNumber?: string | null,
  email?: string | null
): void => {
  logFn(
    'baseeta_onboarding_redirected_timeout',
    {
      page_name: page,
      phoneNumber: phoneNumber,
      email: email,
    },
    'debug',
    'checkout-test'
  );
};

export const logUserSwitchedCountry = (
  logFn: LogFn,
  country: string,
  pagePath: string
): void => {
  logFn(
    'user_switched_country',
    {
      country: country,
      page_path: pagePath,
    },
    'debug',
    'checkout-test'
  );
};

export const logUserSwitchedLanguage = (
  logFn: LogFn,
  locale: string,
  pagePath: string
): void => {
  logFn(
    'user_switched_language',
    {
      locale: locale,
      page_path: pagePath,
    },
    'debug',
    'checkout-test'
  );
};

export const logClickOnBuyback = (logFn: LogFn, trigger: string): void => {
  logFn(
    'click_on_buyback',
    {
      trigger: trigger,
    },
    'buyback',
    ''
  );
};

export const logBeginBuybackFlow = (logFn: LogFn, model: string): void => {
  logFn(
    'begin_buyback',
    {
      model: model,
    },
    'buyback',
    ''
  );
};

export const logShowBuybackOffer = (
  logFn: LogFn,
  model: string,
  productID: string,
  price: number,
  currency: string
): void => {
  logFn(
    'show_buyback_offer',
    {
      model: model,
      product_id: productID,
      price: price,
      currency: currency,
    },
    'buyback',
    ''
  );
};

export const logAcceptBuybackOffer = (
  logFn: LogFn,
  model: string,
  productID: string,
  price: number,
  currency: string
): void => {
  logFn(
    'accept_buyback_offer',
    {
      model: model,
      product_id: productID,
      price: price,
      currency: currency,
    },
    'buyback',
    ''
  );
};

export const logAddBuybackShippingInfo = (
  logFn: LogFn,
  model: string,
  productID: string,
  price: number,
  currency: string
): void => {
  logFn(
    'add_buyback_shipping_info',
    {
      model: model,
      product_id: productID,
      price: price,
      currency: currency,
    },
    'buyback',
    ''
  );
};

export const logAddBuybackIban = (
  logFn: LogFn,
  model: string,
  productID: string,
  price: number,
  currency: string
): void => {
  logFn(
    'add_buyback_iban',
    {
      model: model,
      product_id: productID,
      price: price,
      currency: currency,
    },
    'buyback',
    ''
  );
};

export const logCreateBuybackOrder = (
  logFn: LogFn,
  orderID: string,
  productID: string,
  price: number,
  currency: string
): void => {
  logFn(
    'create_buyback_order',
    {
      order_id: orderID,
      product_id: productID,
      price: price,
      currency: currency,
    },
    'buyback',
    ''
  );
};

export const logUpdateBuybackTrackingInfo = (
  logFn: LogFn,
  orderID: string,
  productID: string
): void => {
  logFn(
    'update_buyback_tracking_info',
    {
      order_id: orderID,
      product_id: productID,
    },
    'buyback',
    ''
  );
};

export const logOpenVideo = (logFn: LogFn, videoId: string): void => {
  logFn(
    'open_video',
    {
      video_id: videoId,
    },
    'engagement',
    ''
  );
};
